<template>
  <div class="navbar-expand-lg nav-light navbar__login-wrapper">
    <div class="container-fluid container-fluid-login">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <a class="navbar-brand" @click="handleBackUrl">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
                fill="#F2F2F2"
              />
            </svg>
          </a>
          <h6 class="title-navbar-login">
            Atur Ulang Kata Sandi
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="card card__form-login">
      <div class="card-body card__body-form">
        <h6 class="title-form">
          Silahkan atur ulang kata sandi anda.
        </h6>
        <form @submit.prevent="handleFormSubmit" class="needs-validation" novalidate>
          <div class="input-group input__group mb-3">
            <span id="basic-addon1" class="input-group-text input__text-login">
              <icon-key-gray />
            </span>
            <input
              type="password"
              class="form-control form__input"
              placeholder="Kata Sandi Baru"
              v-model="password"
              @keyup="handleKeyupPassword"
            />
          </div>
          <div class="input-group input__group mb-1">
            <span id="basic-addon1" class="input-group-text input__text-login">
              <icon-key-gray />
            </span>
            <input
              type="password"
              class="form-control form__input"
              placeholder="Konfirmasi Kata Sandi"
              v-model="confirmPassword"
              @keyup="handleKeyupConfirmPassword"
            />
          </div>
          <div class="form-group signin-group">
            <button
              type="submit"
              :disabled="disabledButtonSubmit"
              class="btn btn-sm btn-warning login-button w-100"
            >
              Simpan
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import IconKeyGray from '../components/Icon/IconKeyGray.vue'

export default {
  name: 'PasswordReset',
  components: { IconKeyGray },
  data() {
    return {
      password: '',
      confirmPassword: '',
      token: this.$route.query.token,
      validations: {
        password: {
          error: false,
          message: null
        },
        confirmPassword: {
          error: false,
          message: null
        }
      }
    }
  },
  computed: {
    disabledButtonSubmit() {
      if (
        this.password === '' ||
        this.confirmPassword === '' ||
        this.validations.password.error === true ||
        this.validations.confirmPassword.error === true
      ) {
        return true
      }

      return false
    }
  },
  methods: {
    handleKeyupPassword() {
      if (this.password === '') {
        this.validations.password = {
          error: true,
          message: 'Password tidak boleh kosong'
        }
      } else if (this.password.length <= 7) {
        this.validations.password = {
          error: true,
          message: 'Password kurang dari 8 karakter'
        }
      } else {
        this.validations.password = {
          error: false,
          message: null
        }
      }
    },
    handleKeyupConfirmPassword() {
      if (this.confirmPassword === '') {
        this.validations.confirmPassword = {
          error: true,
          message: 'Konfirmasi kata sandi tidak boleh kosong'
        }
      } else if (this.confirmPassword !== this.password) {
        this.validations.confirmPassword = {
          error: true,
          message: 'Konfirmasi kata sandi tidak sesuai'
        }
      } else {
        this.validations.confirmPassword = {
          error: false,
          message: null
        }
      }
    },
    handleFormSubmit() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })

      this.$http
        .post(`${process.env.VUE_APP_API_URL}reset-password/reset`, {
          token: this.token,
          password: this.password,
          // eslint-disable-next-line @typescript-eslint/camelcase
          password_confirmation: this.confirmPassword
        })
        .then(() => {
          swal.close()
          swal.hideLoading()
          this.$store.dispatch(
            'notification/success',
            'Password berhasil di reset. Silahkan login kembali'
          )

          this.$router.push('/login')
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
          this.$store.dispatch('notification/error', error)
        })
    }
  }
}
</script>
